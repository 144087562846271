import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import clsx from 'clsx';
import { Carousel } from 'ui/components/Carousel';
import Link from 'next/link';
import { PrismicNextImage, PrismicNextLink } from '@prismicio/next';
import ArrowForward from 'ui/svgs/library/ArrowForward';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { DesktopBreakpoint } from 'ui/components/ResponsiveImage';
import { CommonButton } from 'ui/components/CommonButton';
import { CtaLink } from 'ui/components/CtaLink';

/**
 * Props for `ResourcesCarousel`.
 */
export type ResourcesCarouselProps = SliceComponentProps<Content.ResourcesCarouselSlice>;

/**
 * Component for "ResourcesCarousel" Slices.
 */
const ResourcesCarousel = ({ slice }: ResourcesCarouselProps): JSX.Element => {
  const { primary, items } = slice;
  const { background_color, title, tag_text } = primary;
  const [bg, setBg] = useState(background_color);

  useEffect(() => {
    window.addEventListener('backgroundColorChange', (event: any) => {
      setBg(event.detail.color);
    });
    () => {
      window.removeEventListener('backgroundColorChange', () => {});
    }
  }, []);

  const ref = useRef<any>({
    handlePrevious: () => {},
    handleNext: () => {},
  });

  const [scrollState, setScrollState] = useState({
    canScrollNext: false,
    canScrollPrev: false,
  });

  const handleCanScrollState = useCallback(({ canScrollNext = false, canScrollPrev = false }) => {
    setScrollState({ canScrollNext, canScrollPrev });
  }, []);

  const extractData = (
    item: any,
  ): {
    title: string;
    description: string;
    link: string;
    image: any;
    ctaText: string;
    type: string;
  } => {
    switch (item.relational_document.type) {
      case 'case_study_v2': {
        const { data, url } = item.relational_document;
        return {
          title: data.title,
          description: data.description,
          link: url,
          image: data.image,
          ctaText: item.cta_text,
          type: 'Case Study',
        };
      }
      case 'resources': {
        const { data, url } = item.relational_document;
        return {
          title: data.title,
          description: data.description,
          link: url,
          image: data.image,
          ctaText: item.cta_text,
          type: 'Resource',
        };
      }
      case 'blog': {
        const { data, url } = item.relational_document;
        return {
          title: data.title,
          description: data.description,
          link: url,
          image: data.image,
          ctaText: item.cta_text,
          type: 'Article',
        };
      }
      default:
        return {
          title: '',
          description: '',
          link: '',
          image: '',
          ctaText: '',
          type: '',
        };
    }
  };

  const [width] = useWindowsSize();
  const list = [...(ref.current?.scrollSnapList?.() || [])];
  
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      data-background={background_color}
      className={clsx(
        'wide-container',
        '[ transition-color duration-1500 ease-in-out ][ w-full ][ !pt-0 ][ relative ]',
        {
          '[ bg-gray-97 ]': bg === 'light',
          '[ bg-gray-0 ]': bg === 'dark',
        },
      )}
    >
      <div className="[ w-full ][ px-5 md:px-0 py-8 md:py-10 lg:py-24 ]">
        <div className="[ flex flex-column justify-center lg:justify-between items-center ][ mb-0 lg:mb-14 mt-14 lg:mt-0 px-5 md:px-16 py-0 ]">
          <h2
            className={clsx(
              '[ font-medium text-46 text-wrap leading-tight tracking-tight ][ mb-8 md:mb-14 lg:mb-6 ]',
              {
                '[ text-white ]': bg === 'dark',
                '[ text-gray-0 ]': bg !== 'dark',
              },
            )}
          >
            {title}
          </h2>

          {width >= DesktopBreakpoint && (
            <div className="[ flex flex-column align-end ]">
              <div className="[ flex gap-2 ]">
                <CommonButton
                  onClick={() => {
                    ref.current?.handlePrevious();
                  }}
                  appearance="outline"
                  color={bg === 'dark' ? 'white' : 'black'}
                  disabled={!scrollState.canScrollPrev}
                  hasIcon="only"
                >
                  <ArrowForward fill="currentColor" className="[ rotate-180 ]" />
                </CommonButton>

                <CommonButton
                  onClick={() => {
                    ref.current?.handleNext();
                  }}
                  appearance="outline"
                  color={bg === 'dark' ? 'white' : 'black'}
                  disabled={!scrollState.canScrollNext}
                  hasIcon="only"
                >
                  <ArrowForward fill="currentColor" />
                </CommonButton>
              </div>
            </div>
          )}
        </div>

        <Carousel
          slideSize={448}
          slideGap="lg"
          withControls={false}
          withIndicators={false}
          autoPlay
          loop
          carouselRef={ref}
          handleCanScrollState={handleCanScrollState}
          breakpoints={[
            {
              maxWidth: 'md',
              slideSize: 330,
              slideGap: 'sm',
            },
            {
              maxWidth: 'md',
              slideSize: 366,
              slideGap: 'sm',
            },
          ]}
        >
          {items.map((item, index) => {
            return (
              <Carousel.Slide key={index}>
                <PostSlide bg={bg} {...extractData(item)} />
              </Carousel.Slide>
            );
          })}
        </Carousel>

        {width < DesktopBreakpoint && (
          <div className="[ flex flex-column justify-center lg:justify-between items-center ][ mb-0 lg:mb-14 mt-14 lg:mt-0 px-5 md:px-16 py-0 ]">
            <div className="[ flex flex-column align-end ]">
              <div className="[ flex gap-2 ]">
                <CommonButton
                  onClick={() => {
                    ref.current?.handlePrevious();
                  }}
                  appearance="outline"
                  color={bg === 'dark' ? 'white' : 'black'}
                  disabled={!scrollState.canScrollPrev}
                  hasIcon="only"
                >
                  <ArrowForward fill="currentColor" className="[ rotate-180 ]" />
                </CommonButton>

                <CommonButton
                  onClick={() => {
                    ref.current?.handleNext();
                  }}
                  appearance="outline"
                  color={bg === 'dark' ? 'white' : 'black'}
                  disabled={!scrollState.canScrollNext}
                  hasIcon="only"
                >
                  <ArrowForward fill="currentColor" />
                </CommonButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export const PostSlide = ({ title, description, link, image, ctaText, type, bg }) => {
  return (
    <Link
      href={link}
      className="[ no-underline ][ flex flex-col justify-start align-start ][ h-full ][ mr-5 lg:mr-8 ][ relative ][ group ]"
    >
      <div className="[ transition-height duration-375 ][ rounded-2xl ][ w-full h-58.5 group-hover:h-39.5 overflow-hidden ][ mb-8 ]">
        <PrismicNextImage field={image} className="[ w-full h-full object-cover object-center ]" />
      </div>

      <span
        className={clsx(
          '[ font-medium text-14 uppercase ][ mt-6 mb-2 ]',
          {
            '[ text-blue-60 ]': bg === 'dark',
            '[ text-gray-30 ]': bg !== 'dark',
          },
        )}
      >
        {type}
      </span>

      <div
        className={clsx(
          '[ font-medium text-24 line-clamp-2 ][ mb-4 ]',
          {
            '[ text-white ]': bg === 'dark',
            '[ text-gray-0 ]': bg !== 'dark',
          },
        )}
      >
        {title}
      </div>

      <div>
        <div className="[ transition-all duration-375 ][ grid grid-cols-1 auto-cols-1 grid-rows-0fr group-hover:grid-rows-1fr gap-4 ][ relative ]">
          <div className="[ font-16 text-gray-40 whitespace-pre-line line-clamp-3 ][ my-0 ]">
            {description}
          </div>
        </div>

        <CtaLink underline={false} className="[ font-semibold text-blue-50 ][ w-fit ][ mt-10 ]">
          {ctaText}
        </CtaLink>
      </div>
    </Link>
  );
};

export default ResourcesCarousel;
